<template>
  <div>
    <ul class="list5">
      <li v-for="(item,index) in options" :key="index">
        <div class="con">
          <div class="icon1">
            <img :src="item.link" class="zx" alt>
          </div>
          <div class="txt3">
            <h3 class="h3">{{item.title}}</h3>
            <p>{{item.details}}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options:{
      type:Array,
      default(){
        return []
      }
    }
  },
  name: "CartCollapse"
}
</script>

<style scoped>
.list5 {
  margin-top: 20px;
  list-style: none;
}
.con {
  display: block;
  padding: 30px 741px 30px 20px;
  background-color: #fff;
  overflow: hidden;
}
.icon1 {
  float: left;
  width: 70px;
  height: 70px;
  background: #059ff9;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  margin-right: 2.23958333vw;
}
.txt3 {
  overflow: hidden;
  text-align: justify;
}
.h3 {
  font-size: 20px;
  color: #000;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 5px;
}
.zx{
  position: relative;
  top: 9px;
}
a {
  color: rgb(0 0 0 / 85%);
}
</style>
