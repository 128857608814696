<template>
  <div style="display: flex;flex-direction: column;justify-content: space-between">
    <div class="about">
      <div class="txt">
        <div class="cur">
          <router-link to="/home">首页</router-link>
          <span>>联系我们</span>
        </div>
        <h3 class="tit">联系我们</h3>
      </div>
    </div>
    <div class="container">
      <div class="wp">
        <div class="m-tit5">
          <h3>公司地址</h3>
        </div>
        <div class="inner">
          <img src="../assets/16262439.png">
          <div class="m-addres">
            <h4 class="tit-a">芯片助手ICHELPS</h4>
            <div class="det">
<!--              <p>地址：上海市闵行区东川路555号</p>-->
<!--              <p>电话:021-60907680</p>-->
<!--              <p>邮箱：Keyenes.chang@foxmail.com</p>-->
              <p>联系方式：info@ichelps.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {}
}
</script>
<style >
.about {
  background-image: url("../assets/VCG1.png");
  height: 402px;
  width: 1905px;
}
.txt {
  padding-top: 12.1%;
}
.tit {
  font-size: 30px;
  color: #40a9ff;
  line-height: 1.5;
  font-weight: 400;
  margin-top: -80px;
  margin-left: -1147px;
}
.cur {
  font-size: 16px;
  color: #1890ff;
  margin-left: -1163px;
}
.m-tit5 {
  font-size: 14px;
  color: #888;
  line-height: 1.5;
  text-align: center;
}

.m-tit5 h3 {
  margin-top: 104px;
  font-size: 24px;
  color: #11132f;
  font-weight: 700;
  margin-bottom: 14px;
}
.container {
  margin-bottom: 100px;
}
.wp {
  max-width: 1200px;
  margin: 0 auto;
}
.m-addres {
  float: right;
  width: 40.5%;
  font-family: Arial;
  font-size: 16px;
  color: #bfbfbf;
  font-weight: 700;
  line-height: 1.5;
}
.det {
  position: relative;
  z-index: 1;
  /*padding-left: 36px;*/
  margin-top: 22px;
  text-align:left;
}
.tit-a {
  font-size: 22px;
  color: #000;
  font-weight: 700;
  margin-bottom: 23px;
  text-align:left;
}
</style>
