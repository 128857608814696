import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Bout from '../views/Bout.vue'
import Product from '../views/product.vue'
import Layout from '../components/Layout.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
            },
            {
                path: '/about',
                name: 'About',
                component: About,
            },
            {
                path: '/Bout',
                name: 'Bout',
                component: Bout,
            },
            {
                path: '/search',
                name: 'Search',
                component: ()=>import('@/views/search/index.vue'),
            },
            {
                path: '/product/:id',
                name: 'Product',
                component: Product
            },
            {
                path: '/stock',
                name: 'Stock',
                component: () =>import('@/views/stock/index.vue')
            },
            {
                path: '/details/:id',
                name: 'Details',
                component: () =>import('@/views/stock/details.vue')
            }

        ]
    },
    {path: "/productDetails/:name",name:'ProductDetails',component: () =>import('../views/productDetails/index.vue')}
]

const router = new VueRouter({

    routes
})

export default router
