<template>
  <div class="bottom-box">
    <a href="https://beian.miit.gov.cn/">沪ICP备20025129号-2</a>
  </div>
</template>

<script>
export default {
  name: "BottomNavigation"
}
</script>

<style scoped>
.bottom-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
}
.bottom-box a {
  color: #808080;
}
</style>