<template>
  <div>
    <div class="banner">
      <div class="txt">
        <h3 class="tit">加入我们</h3>
        <div class="cur">
          <router-link to="/home">首页</router-link>
          <span>>加入我们</span>
        </div>
      </div>
    </div>

    <div class="wp">
      <div class="bot">
        <div class="abs">
          <h2>公司介绍</h2>
        </div>
        <div class="l">
          <img src="../assets/222.jpg" alt="">
        </div>
        <div class="oh">
          <h2 style="margin-left: 86px;">芯片助手ICHELPS</h2>
          <p>芯片助手ICHELPS成立于 2018 年，是一家年轻的进出口贸易公司。公司由一群有专业经验的人员组成，立志成为国内外一站式、高可靠产品供
            应链公司。目前公司主要业务为经营进口集成电路。除个别独家授权产品，基本涵盖目前国内常用进口器件品牌。近一年我们也开始进口国外裸心产品供国内院
            所集成使用。<br>公司在上海紫竹开发区建有自己的防静电存储仓库，承诺为长期客户提供稳定的库存安全保障。公司设有技术咨询岗位，可以提供部分高等级宇航
            产品的技术支持服务。我们与进口代理商、服务商保持良好合作关系，与服务的科研院所定期交流，必要时可为客户提供调货服务。 公司目前还具备定制治具,
            非标设备设计能力，核心成员在相关行业从业多年。
            <br>公司宗旨:服务客户、创造价值
          </p>
        </div>
      </div>
    </div>
    <div class="wp">
      <div class="g-tit">
        <p>使命与愿景<br>服务客户 , 创造价值</p>
      </div>
      <cart-collapse :options="options"></cart-collapse>
    </div>
    <div class="wp">
      <div class="g-titb">
        <h3>质量可靠性<br>高质量解决方案</h3>
      </div>
      <p class="p">实现产品卓越要超越行业标准。</p>
      <div class="m-infob6 fix">
        <div class="ll">
          <ul class="ul">
            <li v-for="(item,index) in aaaList" :key="index" :class="{on:activeId===index}" v-on:click="activeId=index">
              <div class="con">
                <div class="txt5" :click="index===activeId">{{ item.title }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="oh1">
          <div>
            <div class="m-txtb1" >
              <h3 >{{ aaaList[activeId].title }}</h3>
              <div class="desc">
                <p>{{aaaList[activeId].desc}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CartCollapse from "@/components/Cart/CartCollapse.vue";
import qy from '../assets/qy.png'
import ln from '../assets/ln.png'
import sm from '../assets/sm.png'

const aaaList = [
  {title:'绿色产品',desc:'芯片助手ICHELPS致力于环境保护，产品符合环保法律法规的要求'},
  {title:'质量声明',desc:'芯片助手ICHELPS致力于建立和不断改进其业内领先的质量保证系统，为此在日常工作中形成了保证质量、改善的企业文化氛围。' +
        '我们通过提供高品质的芯片，快捷的全方位服务，为了客户缩短了产品上市时间，并成为其提高终端产品竞争力强有力的保障。'},
  {title:'质量管理',desc:'芯片助手ICHELPS建立了完善的质量管理部门，质量保证活动覆盖了产品从研发到售后的全部生命工程；除了传统的供应链质量' +
        '管理，率先在行业内开展了研发质量管理。随着近年经验的累积，管理水平在不断的提升，已经逐步获得研发管理带来的收益。通过完善' +
        '的IT平台，全面整合了各模块的质量信息，形成有效的跟踪和改善系统，使得质量管理工作在不断的应用中自身循环提升，实现了高效管' +
        '理的目的。'},
]
const options = [
  {
    title: '企业文化', details: '专业，品质，速度，诚信', link: qy
  },
  {
    title: '企业理念', details: '用”芯“让人们随时随地享受美好生活', link: ln
  },
  {
    title: '企业使命', details: '客户向导，创新发展，善用人才，肯定贡献，成果贡献', link: sm
  },
]
export default {
  components: {
    CartCollapse
  },
  data() {
    return {
      aaaList,
      activeId:0,
      options,
    }
  },
  methods:{
  }
}
</script>

<style lang="less" scoped>

.banner {
  background-image: url("../assets/VCG2.png");
  height: 393px;
  width: 1905px;
}

.cur {
  font-size: 16px;
  color: #1890ff;
  margin-left: -1163px;
  margin-top: -17px;
}

.bot {
  overflow: hidden;
}

.l {
  float: left;
  margin-right: 25px;
}

.oh {
  font-size: 17px;
  line-height: 36px;
  color: #888;
  display: inline;
  text-align: justify;
}

.abs {
  margin-top: 62px;
  font-size: 24px;
}

.wp {
  max-width: 1200px;
  margin: 0 auto;
}

.g-tit {
  font-size: 26px;
  color: #11132f;
  margin-top: 40px;
}
.g-titb {
  font-size: 26px;
  color: #11132f;
  font-weight: 700;
  margin-top: 40px;
}
.p {
  font-size: 16px;
  color: #666;
  margin: 21px 0 51px;
}
.ll {
  margin-right: 28px;
}
.oh1 {
  box-shadow: 0 10px 40px rgb(135 153 163 / 20%);
  border-radius: 4px;
  background-color: #fff;
  padding: 36px;
  height: 252px;
  overflow: hidden;
  margin-block: 60px;
}
.ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.on .con {
  color: #fff;
  background-image: -webkit-linear-gradient(
      315deg
      ,#6a60ee 0,#56edff 100%);
  background-image: -moz- oldlinear-gradient(315deg,#6a60ee 0,#56edff 100%);
  background-image: -o-linear-gradient(315deg,#6a60ee 0,#56edff 100%);
  background-image: linear-gradient(
      135deg
      ,#6a60ee 0,#56edff 100%)
}

.con{
  width: 300px;
  padding-left: 35px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgb(205 205 205 / 75%);
  border-radius: 5px;
  line-height: 60px;
  color: #8799a3;
  cursor: pointer;
  margin-bottom: 36px;

  &:hover{
      color: #fff;
      background-image: -webkit-linear-gradient(
          315deg
          ,#6a60ee 0,#56edff 100%);
      background-image: -moz- oldlinear-gradient(315deg,#6a60ee 0,#56edff 100%);
      background-image: -o-linear-gradient(315deg,#6a60ee 0,#56edff 100%);
      background-image: linear-gradient(
          135deg
          ,#6a60ee 0,#56edff 100%);

  }
}
.txt5 {
  font-size: 20px;
  font-weight: 700;
  width: 222px;
}
.line {
  width: 20px;
  height: 2px;
  margin: 16px 0 18px;
  background-color: #666;
}
.desc {
  font-size: 14px;
  color: #666;
  line-height: 36px;
  min-height: 108px;
  overflow: hidden;
}
</style>
