<template>
  <div>
    <img :src="`/productImg/${image}.jpg`" class="product">
  </div>
</template>

<script>
export default {
  data(){
    return{
      image:'',
    }
  },
  created(){
    this.image = this.$route.params.id
  },
  // 监听
  watch:{
    $route(){
      this.image = this.$route.params.id
    },
  }
}

</script>

<style scoped>

.product{
  object-fit: contain;
  width: 100%;
}
</style>