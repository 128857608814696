<template>
  <div class="layout-container">

    <div class="header">
      <div class="logo"/>
      <div class="menu">
        <div class="menu-item" v-for="(menu,index) in menus" :key="index"
             :class="{'active':$route.path===menu.router,'pdf_upload':menu.pdf}"
             @click="chang(index)">
          <div>
            <router-link v-if="menu.router" :to="menu.router">{{ menu.name }}</router-link>
            <a v-else class="menu-item" target="_blank" :href="menu.url">
              <div>{{ menu.name }}</div>
<!--              <p class="title">UPLOAD FILE</p>-->
            </a>
          </div>
        </div>
      </div>

    </div>
    <div class="contact">
      <a-popover placement="leftTop">
        <p slot="content">021-60907680</p>
        <img src="../assets/dh.png" alt>
      </a-popover>

      <a-dropdown>
        <a class="ant-dropdown-link">
          产品
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item v-on:click="product(2)">激光雷达系列</a-menu-item>
            <a-menu-item v-on:click="product(6)">高端模拟电路</a-menu-item>
            <a-menu-item v-on:click="product(3)">超宽带高性能</a-menu-item>
            <a-menu-item v-on:click="product(4)">特种黑体辐射源</a-menu-item>
            <a-menu-item v-on:click="product(1)">高可靠一站式</a-menu-item>
            <a-menu-item v-on:click="handleMos">MOS管</a-menu-item>
            <a-sub-menu key="test" title="多用途无人机">
              <a-menu-item v-on:click="product(5)">多用途无人机
                <template>
                  <div style="border-bottom: 1px solid #DFDFDFFF;margin-top: 3px"></div>
                </template>
              </a-menu-item>
              <a-menu-item v-on:click="product(7)" >C-50无人机</a-menu-item>
              <a-menu-item v-on:click="product(8)">C-30无人机</a-menu-item>
            </a-sub-menu>
            <a-sub-menu title="星载激光通信终端">
              <a-menu-item v-on:click="product(11)" >LL-Z1</a-menu-item>
              <a-menu-item v-on:click="product(9)" >LL-Z2</a-menu-item>
              <a-menu-item v-on:click="product(10)">LL-Z3</a-menu-item>
              <a-menu-item v-on:click="product(12)">LL-G1</a-menu-item>
              <a-menu-item v-on:click="product(13)">LL-G2</a-menu-item>
              <a-menu-item v-on:click="product(14)">LL-SZ1</a-menu-item>
            </a-sub-menu>
            <a-sub-menu title="驱光系列">
              <a-menu-item v-on:click="product(15)" >LL-R</a-menu-item>
              <a-menu-item v-on:click="product(16)" >LL-2DT</a-menu-item>
              <a-menu-item v-on:click="product(17)">LL-CPA/PM</a-menu-item>
              <a-menu-item v-on:click="product(18)">LL-CPA/E</a-menu-item>
              <a-menu-item v-on:click="product(19)">LL-CPA/PS</a-menu-item>
              <a-menu-item v-on:click="product(20)">LL-CPA/T</a-menu-item>
            </a-sub-menu>
            <a-sub-menu title="太赫兹产品">
              <a-menu-item v-on:click="product(21)" >太赫兹模组</a-menu-item>
              <a-menu-item v-on:click="product(22)" >发射链路</a-menu-item>
              <a-menu-item v-on:click="product(23)">接收链路</a-menu-item>
            </a-sub-menu>
<!--            <a-sub-menu title="MOS">-->
<!--              <a-menu-item>GCP2N6849</a-menu-item>-->
<!--              <a-menu-item>GCFF9120</a-menu-item>-->
<!--            </a-sub-menu>-->
          </a-menu>

        </template>
      </a-dropdown>
    </div>
    <router-view/>
    <div>
      <bottom-navigation></bottom-navigation>
    </div>
  </div>
</template>

<script>



import BottomNavigation from "@/components/BottomNavigation";
const menus = [
  {name: '首页', router: '/home'},
  {name: '关于', router: '/bout'},
  {name: '联系', router: '/about'},
  // {name: '今日库存', router: '/stock'},
  {name: '在线商城', url: 'https://mall.ichelps.com/',},
  // {name: '文档上传', url: 'https://pdf.ichelps.com', pdf: true},
]

const productList = [
  {id: 1, name: '激光雷达系列', img: "2"},
  {id: 2, name: '高端模拟电路', img: "6"},
  {id: 3, name: '超宽带高性能', img: "3"},
  {id: 4, name: '特种黑体辐射源', img: "4"},
  {id: 5, name: '多用途无人机', img: "5"},
  {id: 6, name: 'C-50无人机', img: "7"},
  {id: 7, name: 'C-30无人机', img: "8"},
  {id: 8, name: '高可靠一站式', img: "1"}
]
export default {
  components: {
    BottomNavigation
  },
  created() {
  },
  data() {
    return {
      menus,
      productList
    };
  },
  methods: {
    //点击改变颜色
    chang(index) {
      this.i = index;
    },

    product(e){
      this.$router.replace({
        name: 'Product',
        replace: true,
        params: {id: e}
      })
    },

    handleMos(){
      this.$router.push({
        path:'/productDetails/MOS管'
      })
    }

  }
};

</script>
<style lang="less">

.layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;


  .header {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 0 50px;
    height: 100px;
    line-height: 80px;

    .logo {
      width: 135px;
      height: 33px;
      background: url(../assets/logo.png);
      margin: 24px 28px 16px 0;
      float: left;
      background-size: contain;
    }

    .menu {
      font-size: 26px;
      height: 100%;
      float: right;

      div {
        display: inline-block;
      }
    }

    .menu-item {
      margin: 0 20px;
    }

    .menu-item.active {
      a {
        color: #467ebf;
      }
    }

    .pdf_upload {
      background-color: #404040;
      height: 100%;

      .menu-item {
        display: flex;
        flex-direction: column;
        line-height: 1;
        align-items: baseline;
        color: #FFF;

        .title {
          margin-top: 5px;
          font-size: 9px;
        }
      }

    }

    .blue {
      color: #2c3e50;
    }

  }

  .contact {
    height: 50px;
    position: absolute;
    left: 464px;
    top: 17px;
    z-index: 999;
    flex-direction: row;
  }

  .ddd {
    width: 170px;
    height: 200px;
  }

  a {
    color: #467ebf;
  }
}
.ant-dropdown-link {
  font-size: 23px;
}

</style>
