<template>
  <div class="home">
    <div class="title">
      <div>
        <img src="../assets/logo1.png"/>
      </div>
      <a-input-search v-model="value" placeholder="请输入芯片型号" enter-button="查询一下" size="large" style="width: 600px" @search="onSearch" >
      </a-input-search>
    </div>
  </div>
</template>
<script>
// Modal
import {message} from 'ant-design-vue'


export default {
  components: {
  },
  created() {


  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    onSearch(value) {
      if (!value) {
        message.error('请输入关键字')
        return
      }

      this.$router.push({
        path:'/search',
        query:{
          keyword:value
        }
      })
    }
  },
};

</script>
<style lang="less">

.home {
  //height: 100vh;
  //position: relative;
  overflow: hidden;
  width: 100vw;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;

  // 顶部 begin
  .title {
    margin-top: -167px;
    color: #755e5e;
    line-height: 110px;
  }
  // 顶部 end

  .demo-loadmore-list {
    //border:1px solid #F00;
    background: #FFF;
    width: 1100px;
    margin: 20px auto !important;
    flex: 1;
    overflow: auto;
  }

  // 重写搜索按钮样式
  .ant-btn-primary{

    &,&:hover{
      background-color: #467ebf ;
      border-color: #467ebf;
    }
  }
  .ant-input {
    &,&:hover{
      border:1px solid #467edf;
    }
  }
}

// 全屏Modal begin
.full-modal {
  .page_btn {
    position: fixed;
    height: calc(100vh - 100px);
    width: 50px;
    background: rgba(0, 0, 0, 0.1);
    z-index: 999;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
  }

  .page_left {
    left: 0;
    top: 0;
  }

  .page_right {
    top: 0;
    right: 0;
  }

  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
    width: calc(100vw);
    overflow: auto;
  }

  .ant-modal-body {
    flex: 1;
  }

}

// 全屏Modal end

.ant-avatar {
  border-radius: 0 !important;
}

a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
  width: 597px;
}

.ant-list-item {
  flex-direction: column;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 564px;
}


.ant-spin-nested-loading {
  position: relative;
  margin: 70px auto;
}

.ant-spin {
  max-height: none !important;
}


.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px;
  width: 1360px;
}

.ant-collapse-content {
  border-radius: 0 0 4px 4px;
  height: 816px;
}

.ant-input-group.ant-input-group-compact > *:last-child {
  border-right-width: 0 !important;
}



</style>
